'use client' // Error components must be Client components

import { useEffect } from 'react'

import { LogoLink } from '@/components/shared/logo-link'
import { Button } from '@/components/ui/button'

export default function Error({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error)
  }, [error])

  return (
    <div className="container mx-auto px-4 pb-8 pt-4 lg:max-w-2xl">
      <div className="mb-8">
        <LogoLink />
      </div>

      <h1 className="h1 mb-1">Oops!</h1>
      <h2 className="h3 mb-4">Something went wrong.</h2>

      <Button
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Try again
      </Button>
    </div>
  )
}
